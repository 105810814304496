import { ModelSelect } from 'vue-search-select';
import DatePicker from 'vue2-datepicker';
export default {
  name: 'addCustTdsRateMapping',
  components: {
    ModelSelect,
    DatePicker
  },
  data() {
    return {
      unsubscribe: null,
      custTanNumber: null,
      tdsSection: null,
      rate: null,
      certNumber: null,
      start_date_end_date: null,
      startDate: null,
      endDate: null,
      finYear: null,
      createdBy: null,
      creationDate: null,
      lastUpdatedBy: null,
      lastUpdatedDate: null,
      custTanNumberList: [],
      tdsSectionList: [
        {
          value: '194E',
          text: '194E'
        },
        {
          value: '184C',
          text: '184C'
        },
        {
          value: '196G',
          text: '196G'
        }
      ],
      tdsUploadData: [
        {
          key: 'customer_tan_number'
        },
        {
          key: 'tds_section'
        },
        {
          key: 'rate'
        },
        {
          key: 'start_date'
        },
        {
          key: 'end_date'
        },
        {
          key: 'certificate_number'
        },
        {
          key: 'financial_year'
        },
        {
          key: 'created_by'
        },
        {
          key: 'creation_date'
        },
        {
          key: 'last_updated_by'
        },
        {
          key: 'last_updated_date'
        },
        {
          key: 'flag'
        }
      ]
    };
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          alert('Add');
        }
        if (actionName === 'update') {
          alert('Update');
        }
        if (actionName === 'save') {
          alert('Save');
        }
        if (actionName === 'delete') {
          alert('Delete');
        }
        if (actionName === 'upload') {
          alert('Upload');
        }
        if (actionName === 'download') {
          alert('Download');
        }
      }
    });
  },
  methods: {
    addNewRow() {
      this.tdsUploadData.push({
        line_type: null,
        deductorName: null,
        tanNo: null,
        amountpaid: null,
        taxDeducted: null,
        taxDeposited: null,
        responsibility_dtl_id: 0
      });
    },
    removeRow(index) {
      this.tdsUploadData.splice(index, 1);
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
