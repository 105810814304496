import commonHelper from '@/app/utility/common.helper.utility';
import store from '../../../../store';
import DatePicker from 'vue2-datepicker';
import { ModelSelect, BasicSelect } from 'vue-search-select';
import custTdsEntry from './addCustTdsRateMapping';
import excelUploadEntry from '../twoSixASUpload/addUploadExcel';
export default {
  name: 'custTdsRateMapping',
  components: {
    DatePicker,
    commonHelper,
    custTdsEntry,
    ModelSelect,
    BasicSelect,
    excelUploadEntry,
    store
  },
  watch: {
    currentPage: function() {
      this.getTanRateDetails();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getTanRateDetails();
    }
  },
  data() {
    return {
      unsubscribe: null,
      loader: false,
      showChildModal: false,
      showExcelUploadModal: false,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      currentPage: 1,
      totalRows: null,
      custTanNumber: null,
      tdsSection: null,
      start_date_end_date: [],
      startDate: null,
      endDate: null,
      certificateNo: null,
      tdsSectionList: [
        {
          value: '194E',
          text: '194E'
        },
        {
          value: '184C',
          text: '184C'
        },
        {
          value: '196G',
          text: '196G'
        }
      ],
      data: [],
      fields: [
        {
          key: 'party_name',
          label: 'Customer Name'
        },
        {
          key: 'site_name'
        },
        {
          key: 'tan_no',
          label: 'TAN Number'
        },
        {
          key: 'tds_section',
          label: 'TDS Section'
        },
        {
          key: 'tax_category'
        },
        {
          key: 'start_date'
        },
        {
          key: 'end_date'
        },
        {
          key: 'financial_year'
        },
        {
          key: 'ltdc',
          label: 'LTDC'
        },
        {
          key: 'default_status',
          label: 'Default'
        },
        {
          key: 'amount'
        },
        {
          key: 'currency'
        },
        {
          key: 'certificate_no'
        },
        {
          key: 'active'
        }
      ]
    };
  },
  mounted() {
    this.getTanRateDetails();
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          this.showChildModal = true;
        }
        if (actionName === 'download') {
          this.loader = true;
          /**
           * @param(payload, 'action name', 'file name')
           */
          const downloadpayload = { ...this.payload };
          downloadpayload._limit = this.totalRows;
          this.downloadExcel.downloadData(
            downloadpayload,
            'twoSixASUpload/getTanRateDetails',
            'tan-rate',
            () => (this.loader = false)
          );
        }
        if (actionName === 'print') {
          this.showExcelUploadModal = true;
        }
      }
    });
  },
  methods: {
    getTanRateDetails() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        tan: this.custTanNumber,
        tds_section: this.tdsSection,
        certificate_no: this.certificateNo
      };
      this.loader = true;
      this.$store
        .dispatch('twoSixASUpload/getTanRateDetails', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.data = response.data.data.page;
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    clearTaxGroup() {
      this.custTanNumber = null;
      this.tdsSection = null;
      this.certificateNo = null;
    },
    hideChildModal() {
      this.showChildModal = false;
    },
    openCloseExcelUploadModal(flag) {
      this.showExcelUploadModal = flag;
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
